import React from "react";
import styles from "./LoadingLine.module.css";

interface LoadingLineProps {
  style?: React.CSSProperties;
}

const LoadingLine = ({ style }: LoadingLineProps) => {
  return <div className={styles.loadingLine} style={style} />;
};

export default LoadingLine;
