export function sleep(sleepLengthInMs: number) {
  return new Promise((resolve) => setTimeout(resolve, sleepLengthInMs));
}

export function subarraysOfMaxLen<T>(array: T[], maxLen: number): T[][] {
  const out: any[][] = [];
  for (let start = 0; start < array.length; start += maxLen) {
    out.push(array.slice(start, start + maxLen));
  }
  return out;
}

export function arrayUnion<T, U>(arr1: T[], arr2: U[]): (T | U)[] {
  return Array.from(new Set([...arr1, ...arr2]));
}

export function getYearString(date: Date): string {
  return date.getUTCFullYear().toString();
}

export function dateToYmdString(date: Date): string {
  return `${getYearString(date)}-${getMonthString(date)}-${getDayString(date)}`;
}

export function getNumberInBounds({
  num,
  bounds: { lower, upper },
}: {
  num: number;
  bounds: { lower: number; upper: number };
}): number {
  if (lower > upper) {
    throw new Error(
      `Lower bound {${lower}} cannot be above upper bound {${upper}}`
    );
  }
  if (num < lower) {
    return lower;
  }
  if (num > upper) {
    return upper;
  }
  return num;
}

function getMonthString(date: Date): string {
  return (date.getUTCMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  });
}

function getDayString(date: Date): string {
  return date.getUTCDate().toLocaleString("en-US", { minimumIntegerDigits: 2 });
}

export function getUTCdate(date: Date): Date {
  const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + timeOffsetInMS);
}

export async function returnFirstNonNullPromise<ArgType, ReturnType>({
  functions,
  args,
}: {
  functions: ((args: ArgType) => Promise<ReturnType | null>)[];
  args: ArgType;
}): Promise<ReturnType | null> {
  for (const fn of functions) {
    const result = await fn(args);
    if (result !== null) {
      return result;
    }
  }
  return null;
}
