import { Offer } from "core/types";
import { routes } from "pages/_app";
import styles from "./PreviewOfferLink.module.css";

const PreviewOfferLink = (props: PreviewOfferLinkProps) => {
  return (
    <a
      className={styles.link}
      target="_blank"
      href={`${routes.previewRedirectBase}${props.offer.uid}`}
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};

interface PreviewOfferLinkProps {
  offer: Offer;
  children?: React.ReactNode;
}

export default PreviewOfferLink;
