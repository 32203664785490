import { OnboardingStep } from "modules/Onboarding/steps";
import { useEffect, useState } from "react";
import { getOnboardingStep } from "../firestore/client/onboarding/read";
import { useCurrentUser } from "./useCurrentUser";
import { useFirestore } from "./useFirestore";

export function useOnboardingStep() {
  const firestore = useFirestore();
  const user = useCurrentUser();
  const [step, setStep] = useState<OnboardingStep | null>(
    onboardingStepCache.get
  );
  const shopId = user?.uid;

  useEffect(() => {
    onboardingStepCache.set(step);
  }, [step]);

  useEffect(() => {
    if (!shopId || !firestore) return;
    getOnboardingStep({ firestore, shopId })
      .then((step) => setStep(step))
      .catch((e) => console.error(e));
  }, [firestore, shopId]);

  return step;
}

const onboardingStepKey = "onboarding_step";

const onboardingStepCache = {
  get: () => {
    const shopInfoString = localStorage.getItem(onboardingStepKey);
    if (!shopInfoString) {
      return null;
    }
    return JSON.parse(shopInfoString) as OnboardingStep;
  },
  set: (onboardingStep: OnboardingStep | null) => {
    if (onboardingStepCache === null) {
      localStorage.removeItem(onboardingStepKey);
    } else {
      localStorage.setItem(onboardingStepKey, JSON.stringify(onboardingStep));
    }
  },
};
