import { numToNiceStr } from "./strings";

export function getMoneyString({
  value,
  moneyFormat,
}: {
  value: number | null;
  moneyFormat: string;
}): string {
  moneyFormat = removeHtmlTags(moneyFormat);
  const valueToShow = value === null ? "" : numToNiceStr(value, 2);
  return moneyFormat.replace(amountRegex, valueToShow);
}

function removeHtmlTags(str: string): string {
  str = str
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&pound;/g, "£");
  return str.replace(/<\/?[^>]+(>|$)/g, "");
}

const amountRegex = /{{.*}}/;
