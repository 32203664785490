import { GqlResponseParser, parseGqlResponse } from "./resources/shared";
import { proxyShopifyGraphqlRequest } from "./proxyRequest";
import { shopifyGraphqlRequest } from "./admin/shared";

export async function getShopCurrencyFormat({
  shopId,
  accessToken,
  useProxy,
}: {
  shopId: string;
  accessToken: string;
  useProxy: boolean;
}): Promise<string> {
  const fetcher = useProxy ? proxyShopifyGraphqlRequest : shopifyGraphqlRequest;
  return fetcher({
    accessToken,
    shopId,
    query: getShopQuery(),
  }).then((gqlRes) =>
    parseGqlResponse({ gqlRes, parser: getShopCurrencyFromGqlResponse })
  );
}

const getShopCurrencyFromGqlResponse: GqlResponseParser<string> = ({
  data,
}) => {
  return data.shop.currencyFormats.moneyFormat;
};

function getShopQuery() {
  return `{
        shop {
            currencyFormats {
            moneyFormat
            }
        }
    }`;
}
