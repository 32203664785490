import { useEffect, useState } from "react";
import { useAccessToken } from "./firebase/hooks/useAccessToken";
import { useShopId } from "./firebase/hooks/useShopId";
import { getShopCurrencyFormat } from "./shopifyApi/shop";

export function useMoneyFormat(): string {
  const shopId = useShopId();
  const accessToken = useAccessToken();
  const [moneyFormat, setMoneyFormat] = useState(moneyFormatCache.get);

  useEffect(() => {
    moneyFormatCache.set(moneyFormat);
  }, [moneyFormat]);

  useEffect(() => {
    if (!shopId || !accessToken) return;
    let cancelled = false;
    getShopCurrencyFormat({ shopId, accessToken, useProxy: true })
      .then((newMoneyFormat) => {
        if (cancelled) return;
        setMoneyFormat(newMoneyFormat);
      })
      .catch((e) => console.error(e));
    return () => {
      cancelled = true;
    };
  }, [shopId, accessToken]);

  return moneyFormat;
}

const moneyFormatCacheKey = "money_format";

const moneyFormatCache = {
  get: () => {
    const moneyFormat = localStorage.getItem(moneyFormatCacheKey);
    return moneyFormat ? moneyFormat : "${{amount}}";
  },
  set: (moneyFormat: string) => {
    localStorage.setItem(moneyFormatCacheKey, moneyFormat);
  },
};
