import { Offer } from "core/types";
import type firebase from "firebase/app";
import { OnboardingStep } from "modules/Onboarding/steps";
import { OFFERS_COLLECTION } from "../../tracker/shared/constants";
import { snapshotToOffer } from "../offers/read";
import { OnboardingInfo, ONBOARDING_COLLECTION } from "./shared";

export async function getOnboardingStep({
  firestore,
  shopId,
}: {
  shopId: string;
  firestore: firebase.firestore.Firestore;
}): Promise<OnboardingStep> {
  const onboardingInfo = await firestore
    .collection(ONBOARDING_COLLECTION)
    .doc(shopId)
    .get()
    .then((doc) => {
      const data = doc.data();
      if (!doc.exists || data === undefined) {
        return null;
      }
      return data as OnboardingInfo;
    });
  if (onboardingInfo?.shouldHide) {
    return OnboardingStep.hide;
  }

  const offer = await getAnyOffer({ firestore, shopId });
  if (!offer) {
    return OnboardingStep.createFirstOffer;
  }

  if (!onboardingInfo?.hasPreviewed) {
    return OnboardingStep.previewOffer;
  }

  const activeOffer = await firestore
    .collection(OFFERS_COLLECTION)
    .where("shop", "==", shopId)
    .where("isActive", "==", true)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      const offerSnapshot = querySnapshot.docs[0];
      if (offerSnapshot === undefined) {
        return null;
      }
      return snapshotToOffer(offerSnapshot);
    });

  if (!activeOffer) {
    return OnboardingStep.activateOffer;
  }

  return OnboardingStep.done;
}

export async function getAnyOffer({
  firestore,
  shopId,
}: {
  shopId: string;
  firestore: firebase.firestore.Firestore;
}): Promise<Offer | null> {
  return await firestore
    .collection(OFFERS_COLLECTION)
    .where("shop", "==", shopId)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      const offerSnapshot = querySnapshot.docs[0];
      if (offerSnapshot === undefined) {
        return null;
      }
      return snapshotToOffer(offerSnapshot);
    });
}
