import { Icon, IconSource } from "@shopify/polaris";
import { DropdownMinor } from "@shopify/polaris-icons";
import styles from "./Button.module.scss";

export enum ButtonTypes {
  primary,
  secondary,
  primaryUnclickable,
  secondaryUnclickable,
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  version: ButtonTypes;
  text: string;
  icon?: IconSource;
  isDropdown?: boolean;
  width?: string;
}

function getClassNames(type: ButtonTypes) {
  const classes = [styles.button];
  if (type === ButtonTypes.primary) {
    classes.push(styles.primary);
  } else if (type === ButtonTypes.primaryUnclickable) {
    classes.push(styles.primaryUnclickable);
  } else if (type === ButtonTypes.secondary) {
    classes.push(styles.secondary);
  } else if (type === ButtonTypes.secondaryUnclickable) {
    classes.push(styles.secondaryUnclickable);
  }
  return classes.join(" ");
}

function IconOrNull({ icon }: { icon: IconSource | undefined }) {
  if (!icon) return null;
  return (
    <div className={styles.icon}>
      <Icon source={icon} />
    </div>
  );
}

function DropdownOrNull({ isDropdown }: { isDropdown: boolean | undefined }) {
  if (!isDropdown) return null;
  return (
    <div className={styles.dropdownIcon}>
      <Icon source={DropdownMinor} />
    </div>
  );
}

const Button = ({
  version,
  icon,
  text,
  isDropdown,
  width,
  style,
  ...props
}: ButtonProps) => {
  const classNames = getClassNames(version);
  return (
    <button
      className={classNames}
      style={{ ...style, width: width ? width : "min-content" }}
      {...props}
    >
      <IconOrNull icon={icon} />

      <span className={styles.text}>{text}</span>

      <DropdownOrNull isDropdown={isDropdown} />
    </button>
  );
};

export default Button;
